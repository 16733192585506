.themeToggle {
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--landing-card-bg);
    border: 1px solid var(--landing-border-800);
    cursor: pointer;
    transition: all 0.2s ease;
    z-index: 100;
    box-shadow: var(--landing-card-shadow);
    backdrop-filter: var(--landing-section-backdrop);
}

.themeToggle:hover {
    background: var(--landing-gray-700);
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(236, 72, 153, 0.2);
}

.iconWrapper {
    width: 1.25rem;
    height: 1.25rem;
    color: var(--landing-text);
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    min-height: 100vh;
    background: var(--landing-bg);
    color: var(--landing-text);
}

.heroSection {
    padding: 5rem 1rem;
    text-align: center;
}

.heroSection h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    background: var(--landing-text-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    color: var(--landing-h1);
}

.heroSection p {
    font-size: 1.25rem;
    color: var(--landing-gray-300);
    margin-bottom: 2rem;
}

.buttonContainer {
    display: flex;
    justify-content: center;    
    gap: 1rem;
}

.featuresSection {
    background: var(--landing-section-bg);
    backdrop-filter: var(--landing-section-backdrop);
    border-top: 1px solid var(--landing-border-800);
    border-bottom: 1px solid var(--landing-border-800);
    padding: 5rem 1rem;
}

.sectionContainer {
    max-width: 1200px;
    margin: 0 auto;
}

.featuresSection h2,
.howToPlaySection h2,
.customDecksSection h2,
.testimonialsSection h2,
.ctaSection h2 {
    text-align: center;
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 3rem;
}

.featuresGrid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 768px) {
    .featuresGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .featuresGrid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.featureCard {
    background: var(--landing-card-bg);
    padding: 1.5rem;
    border: 1px solid var(--landing-border-800);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: var(--landing-card-shadow);
    backdrop-filter: var(--landing-section-backdrop);
}

.featureIcon {
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
}

.featureCard h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.featureCard p {
    color: var(--landing-gray-300);
}

.howToPlaySection {
    padding: 5rem 1rem;
}

.stepsGrid {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

@media (min-width: 768px) {
    .stepsGrid {
        flex-direction: row;
    }
}

.stepCard {
    background: var(--landing-card-bg);
    border: 1px solid var(--landing-border-500);
    padding: 1.5rem;
    border-radius: 0.5rem;
    flex: 1;
    box-shadow: var(--landing-card-shadow);
    backdrop-filter: var(--landing-section-backdrop);
}

.stepCard h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.stepCard p {
    color: var(--landing-gray-300);
}

.customDecksSection {
    background: var(--landing-section-bg);
    backdrop-filter: var(--landing-section-backdrop);
    border-top: 1px solid var(--landing-border-800);
    border-bottom: 1px solid var(--landing-border-800);
    padding: 5rem 1rem;
    text-align: center;
}

.customDecksSection p {
    font-size: 1.25rem;
    color: var(--landing-gray-300);
    margin-bottom: 3rem;
}

.testimonialsSection {
    padding: 5rem 1rem;
}

.testimonialsGrid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 768px) {
    .testimonialsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .testimonialsGrid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.testimonialCard {
    background: var(--landing-card-bg);
    border: 1px solid var(--landing-border-500);
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: var(--landing-card-shadow);
    backdrop-filter: var(--landing-section-backdrop);
}

.testimonialCard p:first-child {
    color: var(--landing-gray-300);
    margin-bottom: 1rem;
}

.testimonialCard p:last-child {
    font-weight: 600;
}

.ctaSection {
    background: var(--landing-pink-600);
    padding: 5rem 1rem;
    text-align: center;
}

.ctaSection h2 {
    font-size: 2.25rem;
    margin-bottom: 2rem;
}

.footer {
    background: var(--landing-gray-800);
    padding: 2rem 1rem;
    text-align: center;
}

.footer p {
    color: var(--landing-gray-300);
}

.footer p:last-child {
    margin-top: 0.5rem;
}

.primaryButton,
.secondaryButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 600;
    transition: all 0.2s;
    border: none;
    cursor: pointer;
}

.primaryButton {
    background: var(--landing-pink-600);
    color: var(--landing-button-text);
    box-shadow: 0 2px 4px rgba(236, 72, 153, 0.2);
}

.primaryButton:hover {
    background: var(--landing-pink-700);
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(236, 72, 153, 0.3);
}

.secondaryButton {
    background: white;
    box-sizing: border-box;
    border: 1px solid var(--landing-border-500);
    color: var(--landing-pink-600);
}

.secondaryButton:hover {
    background: var(--landing-gray-100);
    border: 1px solid var(--landing-border-500);
}

