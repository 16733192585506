.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    z-index: 50;
    backdrop-filter: blur(4px);
}

.modernModal {
    background: var(--bg-gradient);
    border-radius: 1rem;
    width: 100%;
    max-width: 56rem;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    animation: modalSlideIn 0.3s ease-out;
}

.modalHeader {
    padding: 1.5rem;
    border-bottom: 1px solid rgba(236, 72, 153, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-primary);
}

.modalContent {
    padding: 1.5rem;
}

.formSection {
    margin-bottom: 2rem;
}

.inputGroup {
    margin-bottom: 1rem;
}

.inputRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.label {
    display: block;
    font-weight: 500;
    color: var(--accent-primary);
    margin-bottom: 0.5rem;
}

.modernInput {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid var(--border-color);
    border-radius: 0.5rem;
    background-color: var(--bg-tertiary);
    color: var(--text-primary);
    transition: all 0.2s;
}

.modernInput:focus {
    outline: none;
    border-color: var(--accent-primary);
    box-shadow: 0 0 0 3px rgba(236, 72, 153, 0.1);
}

.packSection {
    margin-top: 2rem;
}

.sectionTitle {
    color: var(--accent-primary);
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.packGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
    max-height: 450px;
    overflow-y: auto;
}

.packCard {
    background-color: var(--bg-tertiary);
    border-radius: 0.75rem;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;
    border: 2px solid transparent;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.packCard:hover:not(.disabledPack) {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.packImageContainer {
    width: 100%;
    aspect-ratio: 1;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
}

.packImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.packImagePlaceholder {
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #ec4899, #db2777);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: white;
    font-weight: bold;
}

.packInfo {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.packName {
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 0.25rem;
}

.cardCount {
    font-size: 0.875rem;
    color: var(--text-secondary);
}

.selectedPack {
    border-color: var(--accent-primary);
    background-color: var(--bg-secondary);
}

.selectedCheckmark {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: var(--accent-primary);
    color: var(--text-white);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
}

.disabledPack {
    opacity: 0.5;
    cursor: not-allowed;
}

.modalFooter {
    padding: 1.5rem;
    border-top: 1px solid rgba(236, 72, 153, 0.1);
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.modernButton {
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: all 0.2s;
    background-color: var(--button-primary);
    color: var(--text-white);
    border: none;
    cursor: pointer;
}

.modernButton:hover:not(:disabled) {
    background-color: var(--button-primary-hover);
}

.modernButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.ghostButton {
    background-color: transparent;
    color: var(--accent-primary);
    border: 2px solid var(--border-color);
}

.ghostButton:hover {
    background-color: var(--bg-secondary);
    border-color: var(--accent-primary);
}

@keyframes modalSlideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 640px) {
    .inputRow {
        grid-template-columns: 1fr;
    }

    .packGrid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

.sliderContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.slider {
    flex: 1;
    height: 4px;
    -webkit-appearance: none;
    appearance: none;
    background: var(--border-color);
    border-radius: 2px;
    outline: none;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: var(--accent-primary);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
}

.slider::-webkit-slider-thumb:hover {
    background: var(--button-primary-hover);
    transform: scale(1.1);
}

.numberInput {
    width: 80px !important;
}

.packSelectionContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.packSelectionHeader {
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem;
}

.sortingOptions {
    display: flex;
    gap: 0.5rem;
    background: var(--bg-secondary);
    padding: 0.25rem;
    border-radius: 0.5rem;
}

.sortButton {
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    color: var(--text-secondary);
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.sortButton:hover {
    color: var(--text-primary);
    background: var(--bg-hover);
}

.sortButton.active {
    background: var(--accent-primary);
    color: white;
}

.packStats {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.packMetrics {
    display: flex;
    gap: 1rem;
    font-size: 0.75rem;
    color: var(--text-secondary);
}

.rating {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: #ffd700;
}

.usageCount {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--text-secondary);
}

.packCard {
    position: relative;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.packCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}