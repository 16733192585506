.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
    animation: fadeIn 0.3s ease-out;
}

.modalContent {
    background: var(--bg-secondary);
    border-radius: 1rem;
    padding: 2rem;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.3s ease-out;
}

.voteContent,
.cardSelectionContent {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium);
}

.voteHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.voteHeader h3 {
    color: var(--accent-primary);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.timer {
    background-color: var(--accent-primary);
    color: var(--text-white);
    padding: 0.5rem 1rem;
    border-radius: 999px;
    font-weight: 500;
}

.voteInfo {
    background-color: var(--bg-primary);
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--border-color);
}

.initiatorText {
    margin: 0 0 var(--spacing-medium) 0;
    font-weight: bold;
}

.progressContainer {
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 999px;
    height: 20px;
    position: relative;
    overflow: hidden;
}

.progressBar {
    background-color: var(--accent-primary);
    height: 100%;
    transition: width 0.3s ease;
}

.progressText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--text-primary);
    font-size: 0.875rem;
    font-weight: 500;
}

.voterList {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
}

.voterItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-small);
    background-color: var(--background-color);
    border-radius: var(--card-border-radius);
}

.voterName {
    font-weight: 500;
}

.agree {
    color: #4CAF50;
    font-weight: bold;
}

.disagree {
    color: #f44336;
    font-weight: bold;
}

.voteActions {
    display: flex;
    gap: var(--spacing-medium);
    margin-top: var(--spacing-medium);
}

.voteButton {
    flex: 1;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: all 0.2s ease;
}

.voteButton:hover {
    transform: translateY(-2px);
}

.agreeButton {
    background-color: var(--accent-primary);
    color: var(--text-white);
}

.disagreeButton {
    background-color: var(--button-secondary);
    color: var(--text-primary);
}

.cardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin: 1.5rem 0;
}

.cardItem {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    min-height: 120px;
}

.cardItem:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.cardItem.selected {
    border-color: var(--accent-primary);
    background-color: var(--bg-secondary);
}

.cardText {
    font-size: 0.9rem;
    line-height: 1.4;
}

.selectedOverlay {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #4CAF50;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmButton {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: var(--spacing-medium);
    border-radius: var(--card-border-radius);
    font-weight: bold;
    margin-top: var(--spacing-medium);
}

.confirmButton:disabled {
    background-color: var(--background-color);
    color: var(--text-color);
}

.selectionInfo {
    text-align: center;
    color: var(--text-color);
    margin: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.voteResult {
    text-align: center;
    padding: 2rem;
    border-radius: 0.5rem;
    animation: bounceIn 0.5s ease-out;
}

.voteResult h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.voteResult.passed {
    background-color: rgba(76, 175, 80, 0.1);
    border: 1px solid #4CAF50;
}

.voteResult.passed h3 {
    color: #4CAF50;
}

.voteResult.failed {
    background-color: rgba(244, 67, 54, 0.1);
    border: 1px solid #f44336;
}

.voteResult.failed h3 {
    color: #f44336;
}

.nextStep {
    margin-top: 1rem;
    font-style: italic;
    color: var(--text-secondary);
}

@keyframes bounceIn {
    0% {
        transform: scale(0.3);
        opacity: 0;
    }

    50% {
        transform: scale(1.05);
    }

    70% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.waitingState {
    text-align: center;
    padding: 2rem;
    background-color: var(--bg-primary);
    border-radius: 0.5rem;
    animation: fadeIn 0.3s ease-out;
}

.waitingText {
    color: var(--text-secondary);
    margin: 1rem 0;
}

.selectionProgress {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 1.5rem;
}

.playerStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background-color: var(--bg-secondary);
    border-radius: 0.5rem;
}

.playerName {
    font-weight: 500;
}

.ready {
    color: #4CAF50;
    font-weight: bold;
}

.waiting {
    color: var(--text-secondary);
    font-style: italic;
}

.cuteCard {
    background: linear-gradient(135deg, var(--bg-secondary), var(--bg-tertiary));
    border: 2px solid var(--accent-primary);
    border-radius: 1.5rem;
    overflow: hidden;
    width: 300px;
    animation: slideIn 0.3s ease-out;
}

.cuteHeader {
    background-color: var(--accent-primary);
    padding: 1rem;
    text-align: center;
}

.cuteTitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--text-white);
    margin: 0;
}

.cuteContent {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.initiatorMessage {
    text-align: center;
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--text-primary);
    animation: popIn 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.voteProgress {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.progressStats {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: var(--text-secondary);
}

.progressBar {
    height: 1rem;
    background-color: var(--bg-secondary);
    border-radius: 999px;
    overflow: hidden;
    position: relative;
}

.progressIndicator {
    height: 100%;
    background-color: var(--accent-primary);
    transition: width 0.3s ease;
}

.timerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--text-primary);
}

.timerText {
    font-size: 1.125rem;
    font-weight: bold;
    animation: pulse 1s infinite;
}

.cuteFooter {
    display: flex;
    gap: 0.5rem;
    padding: 1rem;
    border-top: 1px solid var(--border-color);
}

.voteButton {
    width: 100%;
    background-color: var(--accent-primary);
    color: var(--text-white);
    padding: 0.75rem;
    border-radius: 0.75rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
}

.voteButton:hover {
    transform: translateY(-2px);
    filter: brightness(1.1);
}

.voteButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
}

@keyframes popIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pulse {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.7;
    }
}

/* Add these new styles for the selection modal */
.selectionCard {
    background: linear-gradient(135deg, var(--bg-secondary) 0%, var(--bg-tertiary) 100%);
    border: 2px solid var(--accent-primary);
    border-radius: 1.5rem;
    overflow: hidden;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.selectionHeader {
    background: var(--accent-primary);
    padding: 1.5rem;
    position: relative;
}

.selectionTitle {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--text-white);
    text-align: center;
    margin: 0;
}

.selectionSubtitle {
    color: var(--text-white);
    text-align: center;
    margin-top: 0.5rem;
    font-size: 1.1rem;
}

.selectionContent {
    padding: 1.5rem;
}

.selectionProgress {
    margin-bottom: 1.5rem;
}

.progressTracker {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
}

.progressTrack {
    height: 0.5rem;
    background-color: var(--bg-secondary);
    border-radius: 999px;
    overflow: hidden;
}

.progressFill {
    height: 100%;
    background: linear-gradient(to right, var(--accent-primary), var(--button-primary));
    transition: width 0.3s ease;
}

.playerGrid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 40vh;
    overflow-y: auto;
    padding-right: 0.5rem;
}

.playerBox {
    background: var(--card-bg);
    border: 2px solid var(--border-color);
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
}

.playerBox.playerReady {
    background: linear-gradient(135deg, var(--bg-secondary), var(--bg-tertiary));
    border-color: var(--accent-primary);
    box-shadow: 0 4px 12px rgba(236, 72, 153, 0.2);
}

.playerDetails {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.playerInitials {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 9999px;
    background: linear-gradient(135deg, var(--accent-primary), var(--button-primary));
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-white);
    font-weight: bold;
}

.playerLabel {
    font-weight: 500;
    color: var(--text-primary);
}

.statusBadge {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    background: var(--accent-primary);
    color: var(--text-white);
    font-size: 0.875rem;
}

.playerBox:not(.playerReady) .statusBadge {
    background: var(--bg-tertiary);
    color: var(--text-secondary);
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1.5rem;
    max-height: 60vh;
    overflow-y: auto;
}

.cardChoice {
    background: var(--card-bg);
    border: 2px solid var(--border-color);
    border-radius: 1rem;
    padding: 1.5rem;
    min-height: 120px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardChoice:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: var(--bg-tertiary);
}

.cardChoice.cardSelected {
    background: linear-gradient(135deg, var(--bg-secondary), var(--bg-tertiary));
    border-color: var(--accent-primary);
    box-shadow: 0 4px 12px rgba(236, 72, 153, 0.2);
}

.cardContent {
    text-align: center;
    font-size: 0.9rem;
    color: var(--text-primary);
}

.selectionMark {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: var(--accent-primary);
    color: var(--text-white);
    border-radius: 50%;
    padding: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: popIn 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.selectionFooter {
    background: var(--bg-secondary);
    padding: 1.5rem;
    border-top: 2px solid var(--border-color);
}

.selectionButton {
    width: 100%;
    background: linear-gradient(135deg, var(--accent-primary), var(--button-primary));
    color: var(--text-white);
    padding: 1rem;
    border-radius: 999px;
    font-size: 1.1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.selectionButton:hover:not(:disabled) {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(236, 72, 153, 0.3);
}

.selectionButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

@keyframes slideDown {
    from {
        transform: translate(-50%, -100%);
        opacity: 0;
    }

    to {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}