/* Modern UI Theme */
.modernLayout {
    min-height: 100vh;
    background-color: var(--bg-secondary);
    /* pink-50 */
}



.modernTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-primary);
}

.modernContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.modernGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-bottom: 1rem;
    max-height: 300px;
    overflow-y: auto;
}

@media (min-width: 768px) {
    .modernGrid {
        grid-template-columns: 2fr 1fr;
    }
}

.modernCard {
    background-color: var(--bg-secondary);
    border-radius: 0.5rem;
    padding: 0 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.modernCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.modernCardTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
}

.modernBlackCard {
    font-size: 1.25rem;
    font-weight: bold;
}

.modernCardsGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
}

.modernPlayersList {
    padding: 1rem;
}

.modernPlayerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: background-color 0.2s;
}

.modernPlayerItem:hover {
    background-color: var(--bg-secondary);
}

.modernPlayerInfo {
    display: flex;
    color: var(--text-primary);
    align-items: center;
    gap: 0.5rem;
}

.modernAvatar {
    width: 2rem;
    height: 2rem;
    background-color: var(--accent-primary);
    color: var(--text-white);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.modernPlayerStats {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.modernScore {
    color: var(--text-tertiary);
    font-size: 0.875rem;
}

.modernBadge {
    background-color: var(--accent-primary);
    color: var(--text-white);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
}

.modernHandGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    padding: 1rem;
}

.modernHandCard {
    height: 8rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    background-color: var(--bg-secondary);
}

.modernHandCard:hover:not(.modernDisabled) {
    background-color: var(--bg-secondary);
    border-color: var(--accent-primary);
}

.modernSelected {
    background-color: var(--bg-secondary);
    border-color: var(--accent-primary);
    transform: translateY(-0.25rem);
    box-shadow: 0 4px 6px -1px rgba(236, 72, 153, 0.1);
}

.modernDisabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.modernFooter {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--border-color);
}

.modernButton {
    background-color: var(--button-primary);
    color: var(--text-white);
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.modernButton:hover:not(:disabled) {
    background-color: var(--button-primary-hover);
}


.modernNotification {
    position: fixed;
    top: 1rem;
    right: 1rem;
    background-color: var(--bg-secondary);
    border-left: 4px solid var(--accent-primary);
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    z-index: 50;
}


.blackCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

/* Add these new styles to ModernUI.module.css */

.playedCardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    overflow-y: auto;
    padding: 1rem;
    max-height: calc(90vh - 200px);
}

.playedCardSlot {
    animation: cardAppear 0.3s ease-out forwards;
    opacity: 0;
    transform: scale(0.9);
}

@keyframes cardAppear {
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    cursor: pointer;
}

.revealedCard .cardInner {
    transform: rotateY(180deg);
}

.cardBack,
.cardFront {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cardBack {
    background-color: var(--accent-primary);
    color: var(--text-white);
    font-weight: bold;
}

.cardFront {
    background-color: white;
    color: black;
    transform: rotateY(180deg);
    border: 2px solid var(--accent-primary);
}

.cardContent {
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.4;
}

.andSymbol {
    color: var(--text-tertiary);
    font-size: 0.875rem;
}

.emptySlot {
    opacity: 0.3;
    pointer-events: none;
}

.winnerCard {
    animation: glowAnimation 2s infinite alternate;
}

.selectableCard:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease;
}


@keyframes glowAnimation {
    from {
        box-shadow: 0 0 10px var(--accent-primary), 0 0 20px var(--accent-primary), 0 0 30px var(--accent-primary);
    }

    to {
        box-shadow: 0 0 20px var(--accent-primary), 0 0 30px var(--accent-primary), 0 0 40px var(--accent-primary);
    }
}

/* Add responsive styles */
@media (max-width: 768px) {
    .playedCardSlot {
        width: 150px;
        height: 210px;
    }

    .modernBlackCard {
        width: 100%;
    }

    .cardContent {
        font-size: 0.875rem;
    }
}

/* Update the selection modal styles */
.selectionModal {
    max-width: 56rem;
    /* 4xl = 56rem = 896px in Tailwind */
    height: 90vh;
    max-height: fit-content;
    background: linear-gradient(to bottom right, var(--bg-secondary), var(--bg-secondary));
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modernBlackCard {
    width: 100%;
    max-width: 32rem;
    background-color: black;
    color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-top: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}





.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.revealedCard .cardInner {
    transform: rotateY(180deg);
}

.cardBack,
.cardFront {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.cardBack {
    background-color: var(--bg-secondary);
    border: 4px solid var(--accent-primary);
    color: var(--text-primary);
    font-weight: bold;
}

.cardFront {
    background-color: white;
    border: 4px solid var(--accent-primary);
    color: var(--text-primary);
    transform: rotateY(180deg);
}

.selectableCard {
    box-shadow: 0 0 15px rgba(251, 191, 36, 0.5);
}

.crownButton {
    margin-top: 2rem;
    background: linear-gradient(to right, #c084fc, #f472b6);
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 9999px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s;
    border: none;
    cursor: pointer;
}

.crownButton:hover {
    transform: scale(1.05);
    background: linear-gradient(to right, #a855f7, #ec4899);
}

.crownButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
}

/* Add animation keyframes */
@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slideIn {
    animation: slideIn 0.5s ease-out forwards;
}

.selectedWinningCard {
    box-shadow: 0 0 15px var(--accent-primary);
    transform: scale(1.05);
}

.selectedWinningCard .cardFront {
    border-color: var(--accent-primary);
}

.crownButton:disabled {
    background: linear-gradient(to right, #9ca3af, #d1d5db);
    transform: none;
    cursor: not-allowed;
}

.selectableCard {
    transition: all 0.2s ease;
}

.selectableCard:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px var(--accent-primary);
}

.modernText {
    color: var(--text-tertiary);
    margin-bottom: 1rem;
    text-align: center;
}

.modernCardContent {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.modernButton:disabled {
    background-color: var(--button-secondary);
    opacity: 0.5;
    cursor: not-allowed;
}

.ghostButton {
    background-color: transparent;
    color: var(--button-primary);
}

.ghostButton:hover {
    background-color: var(--button-secondary) !important;
}

.iconButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.modernSearchSection {
    margin-top: 1rem;
    padding: 1rem;
    position: relative;
}

.searchContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.modernSearchInput {
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
}

.modernSearchInput:focus {
    outline: none;
    border-color: var(--accent-primary);
}

.searchButton {
    background-color: var(--accent-primary);
    color: var(--text-white);
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    white-space: nowrap;
}

.searchButton:hover {
    background-color: var(--button-primary-hover);
}

.notificationTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--accent-primary);
    color: var(--accent-primary);
}

.closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--accent-primary);
    padding: 0;
    line-height: 1;
}

.closeButton:hover {
    color: var(--button-primary-hover);
}

.modernPlayerInfo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.notificationItem {
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s;
}

.notificationItem:hover {
    background-color: var(--bg-secondary);
}

.notificationItem h4 {
    margin: 0;
    font-weight: bold;
    color: var(--accent-primary);
}

.notificationDropdown {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: var(--bg-secondary);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 0.5rem;
    padding: 1rem;
    z-index: 999;
    animation: dropdownFadeIn 0.2s ease-out;
}

.notificationList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 300px;
    overflow-y: auto;
}

.notificationItem {
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: all 0.2s ease;
    animation: itemSlideIn 0.3s ease-out forwards;
    opacity: 0;
    transform: translateY(10px);
}

.searchLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: var(--accent-primary);
    gap: 0.5rem;
}

.searchLoadingDot {
    width: 8px;
    height: 8px;
    background-color: var(--accent-primary);
    border-radius: 50%;
    animation: loadingDot 1s infinite;
}

.searchLoadingDot:nth-child(2) {
    animation-delay: 0.2s;
}

.searchLoadingDot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes dropdownFadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes itemSlideIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes loadingDot {

    0%,
    100% {
        transform: translateY(0);
        opacity: 0.3;
    }

    50% {
        transform: translateY(-4px);
        opacity: 1;
    }
}

/* Game Over Modal Styles */
.gameOverContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.gameOverModal {
    background-color: var(--bg-secondary);
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 28rem;
    margin: 0 auto;
}

.gameOverContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.gameOverHeader {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.gameOverTrophy {
    font-size: 2rem;
    color: var(--accent-primary);
}

.gameOverTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--accent-primary);
}

.gameOverButtons {
    display: flex;
    gap: 1rem;
}

.gameOverBackButton {
    background-color: var(--ghost-button);
    border: 1px solid var(--border-color);
    color: var(--text-secondary);
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s;
}

.gameOverBackButton:hover {
    background-color: var(--button-primary-hover);
}

.gameOverRematchButton {
    background-color: var(--button-primary);
    color: var(--text-white);
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
}

.gameOverRematchButton:hover {
    background-color: var(--button-primary-hover);
}

.gameOverWinnerBox {
    text-align: center;
    background-color: var(--bg-secondary);
    padding: 1rem;
    border-radius: 0.5rem;
    width: 100%;
}

.gameOverWinnerLabel {
    color: var(--accent-primary);
    font-weight: 500;
}

.gameOverWinnerName {
    font-size: 1.125rem;
    font-weight: bold;
    color: var(--accent-primary);
    margin-top: 0.25rem;
}


.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
}

.playedCardsModal {
    background: var(--bg-secondary);
    border-radius: 1rem;
    padding: 2rem;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    animation: slideIn 0.3s ease-out;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.playedOverlay {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--bg-overlay);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.8em;
}


.cardCzarOverlay {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    background-color: var(--bg-overlay);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.8em;
}

.modalHeader {
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.modalTitle {
    color: var(--accent-primary);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.modalFooter {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--border-color);
}

.slideInUp {
    animation: slideInUp 0.3s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.sliderContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.slider {
    flex: 1;
    -webkit-appearance: none;
    height: 4px;
    background: var(--border-color);
    border-radius: 2px;
    outline: none;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: var(--accent-primary);
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s;
}

.slider::-webkit-slider-thumb:hover {
    background: var(--accent-primary);
}

.numberInput {
    width: 60px;
    text-align: center;
}

/* Rating Section Styles */
.packsRatingSection {
    padding: 2rem;
    background: var(--bg-secondary);
    border-radius: 1rem;
    margin: 2rem 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    max-height: 300px;
    overflow: auto;
}

.packsRatingTitle {
    color: var(--accent-primary);
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-align: center;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid var(--border-color);
}

.packRatingContainer {
    background: var(--bg-primary);
    border-radius: 0.75rem;
    padding: 1.5rem;
    margin-bottom: 1rem;
    transition: transform 0.2s ease;
    border: 1px solid var(--border-color);
    min-height: 120px;
}

.packRatingContainer:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.packInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.packName {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-primary);
}

.packType {
    font-size: 0.875rem;
    color: var(--accent-primary);
    padding: 0.25rem 0.75rem;
    background: var(--bg-secondary);
    border-radius: 999px;
    font-weight: 500;
}

.ratingButtons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.ratingButton {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 999px;
    border: 2px solid var(--border-color);
    background: var(--bg-secondary);
    color: var(--text-primary);
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ratingButton:hover {
    border-color: var(--accent-primary);
    background: var(--accent-primary);
    color: white;
    transform: scale(1.1);
}

.ratingButton:active {
    transform: scale(0.95);
}

.ratingButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
}

/* Add animation for the rating buttons */
@keyframes ratingPop {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.ratingButton {
    animation: ratingPop 0.3s ease forwards;
}

.ratingButton:nth-child(1) {
    animation-delay: 0.1s;
}

.ratingButton:nth-child(2) {
    animation-delay: 0.2s;
}

.ratingButton:nth-child(3) {
    animation-delay: 0.3s;
}

.ratingButton:nth-child(4) {
    animation-delay: 0.4s;
}

.ratingButton:nth-child(5) {
    animation-delay: 0.5s;
}

/* Add these new styles */
.ratedMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem;
    color: var(--accent-primary);
    font-weight: 500;
    animation: fadeIn 0.3s ease-in-out;
}

.ratedIcon {
    background: var(--accent-primary);
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    animation: scaleIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(5px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scaleIn {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

/* Add these new styles to your existing ModernUI.module.css */



.voteButton:disabled {
    background-color: var(--background-color);
    color: var(--text-color);
    cursor: not-allowed;
}

.voteButton:not(:disabled):hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
    margin-bottom: var(--spacing-medium);
}

.input {
    padding: var(--spacing-small);
    border: 2px solid var(--background-color);
    border-radius: var(--card-border-radius);
    font-size: 1rem;
    width: 100%;
}

.input:focus {
    border-color: var(--primary-color);
    outline: none;
}

.modalHeader {
    margin-bottom: var(--spacing-medium);
}

.modalBody {
    margin-bottom: var(--spacing-large);
}

.modalFooter {
    display: flex;
    gap: var(--spacing-medium);
    justify-content: flex-end;
}

.primaryButton {
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.secondaryButton {
    background-color: var(--background-color);
    color: var(--text-color);
}

/* Vote Modal Styles */
.voteModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
}

.voteModalContent {
    background: var(--bg-secondary);
    border-radius: 1.5rem;
    padding: 1.5rem;
    width: 90%;
    max-width: 400px;
    animation: slideIn 0.3s ease-out;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--border-color);
}

.voteModalHeader {
    margin-bottom: 1.5rem;
    text-align: center;
}

.voteModalHeader h3 {
    color: var(--accent-primary);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.voteModalBody {
    margin-bottom: 1.5rem;
}

.voteInputGroup {
    border-radius: 1rem;
    padding: 1.25rem;
}

.voteInputLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text-primary);
    font-weight: 500;
    margin-bottom: 1rem;
}

.voteNumberInput {
    width: 60px;
    padding: 0.25rem 0.5rem;
    border: 2px solid var(--border-color);
    border-radius: 0.5rem;
    background: var(--bg-secondary);
    color: var(--text-primary);
    font-size: 1rem;
    text-align: center;
    transition: all 0.2s ease;
}

.voteNumberInput:focus {
    outline: none;
    border-color: var(--accent-primary);
}

.voteModalFooter {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
}


.voteButton {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: var(--spacing-small) var(--spacing-medium);
    border-radius: var(--card-border-radius);
    font-weight: bold;
    margin-left: auto;
    font-size: 1rem;
    transition: all 0.2s ease;
    text-align: center;
}

.votePrimaryButton {
    background: var(--accent-primary);
    color: white;
}

.votePrimaryButton:hover:not(:disabled) {
    transform: translateY(-2px);
    filter: brightness(1.1);
}

.voteSecondaryButton {
    background: transparent;
    border: 1px solid var(--border-color);
    color: var(--text-primary);
}

.voteSecondaryButton:hover {
    background: var(--bg-primary);
}

/* Update slider styles for better visibility in light mode */
.sliderContainer .slider {
    height: 6px;
    background: var(--border-color);
    border-radius: 3px;
}

.sliderContainer .slider::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    background: var(--accent-primary);
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sliderContainer .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: var(--accent-primary);
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive styles */
@media (max-width: 640px) {
    .voteModalContent {
        width: 95%;
        padding: 1.5rem;
    }

    .voteModalFooter {
        flex-direction: column;
    }

    .voteButton {
        width: 100%;
    }
}

.overlayIcon {
    border-radius: 9999px;
    padding: 0.375rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    background-color: var(--accent-primary);
}

.playedGradient {
    background: linear-gradient(to top, var(--accent-primary-50), transparent);
}

.czarGradient {
    background: linear-gradient(to top, var(--accent-secondary-50), transparent);
}

.playedLock {
    color: var(--accent-primary-700);
}

.czarLock {
    color: var(--accent-secondary-700);
}