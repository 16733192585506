.gamesList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.gameCard {
    background: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 1rem;
    transition: all 0.3s ease;
}

.gameCard:hover {
    border-color: var(--button-primary);
}

.gameCardContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.gameInfo h3 {
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: var(--text-primary);
}

.hostInfo {
    font-size: 0.875rem;
    color: var(--text-secondary);
}

.joinButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: var(--button-primary);
    color: var(--text-white);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.joinButton:hover:not(.disabled) {
    background: var(--button-primary-hover);
    box-shadow: 0 0 10px hsl(336 74% 69% / 0.3);
}

.joinButton.disabled {
    background: var(--bg-tertiary);
    color: var(--text-tertiary);
    cursor: not-allowed;
}

.ghostContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: var(--text-tertiary);
}

.ghostIcon {
    margin-bottom: 0.5rem;
    opacity: 0.5;
    color: var(--text-secondary);
}

/* Hide search form since it's been moved */
.searchForm,
.searchInput,
.searchButton,
.createNewGameButton {
    display: none;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background: var(--bg-tertiary);
    color: var(--text-secondary);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.deleteButton.confirm {
    background: var(--button-primary);
    color: var(--text-white);
    border-color: var(--button-primary);
}

.deleteButton.confirm:hover {
    background: var(--button-primary-hover);
}

.deleteButton.cancel {
    background: var(--bg-tertiary);
    color: var(--text-secondary);
}

.deleteButton.cancel:hover {
    background: var(--button-secondary);
    color: var(--text-primary);
}

.deleteButton:hover {
    background: var(--button-secondary);
    color: var(--text-primary);
    border-color: var(--button-primary);
}