:root[data-theme="light"] {
    /* Background colors */
    --bg-primary: hsl(350 100% 98%);
    --bg-secondary: hsl(0 0% 100%);
    --bg-tertiary: hsl(336 50% 95%);
    --bg-gradient: linear-gradient(to bottom right, hsl(336 74% 92%), hsl(336 74% 92%));

    --bg-nav: hsl(336 74% 85%);

    --bg-overlay: hsl(336 74% 69% / 100%);
    --bg-shadowBox: hsl(0 84% 60%);

    /* Text colors */
    --text-primary: hsl(336 10% 20%);
    --text-secondary: hsl(336 10% 40%);
    --text-tertiary: hsl(336 50% 30%);
    --text-white: hsl(0 0% 100%);

    --accent-primary: hsl(336 74% 69%);

    /* Border colors */
    --border-color: hsl(336 74% 85%);

    /* Card colors */
    --card-bg: hsl(0 0% 100%);
    --card-border: hsl(336 74% 85%);

    /* Button colors */
    --button-primary: hsl(336 74% 69%);
    --button-primary-hover: hsl(336 74% 60%);
    --button-secondary: hsl(336 50% 95%);
    --button-secondary-hover: hsl(336 50% 85%);
}

:root[data-theme="dark"] {
    /* Background colors */
    --bg-primary: hsl(336 30% 8%);
    --bg-secondary: hsl(336 30% 11%);
    --bg-tertiary: hsl(336 30% 15%);
    --bg-gradient: linear-gradient(to bottom right, hsl(336 30% 15%), hsl(336 30% 11%));

    --bg-nav: hsl(336 50% 25%);

    --bg-overlay: hsl(336 74% 69% / 100%);

    /* Text colors */
    --text-primary: hsl(336 50% 90%);
    --text-secondary: hsl(336 50% 70%);
    --text-tertiary: hsl(336 50% 90%);
    --text-white: hsl(0 0% 100%);

    /* Border colors */
    --border-color: hsl(336 50% 25%);

    /* Card colors */
    --card-bg: hsl(336 30% 11%);
    --card-border: hsl(336 50% 25%);

    /* Button colors */
    --button-primary: hsl(336 74% 69%);
    --button-primary-hover: hsl(336 74% 60%);
    --button-secondary: hsl(336 50% 25%);
    --button-secondary-hover: hsl(336 50% 30%);

    --accent-primary: hsl(336 74% 69%);
}