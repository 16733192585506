.authModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.authModal.visible {
    opacity: 1;
}

.authModalContent {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    position: relative;
    transform: translateY(-20px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #888;
    transition: color 0.3s ease;
}

.closeButton:hover {
    color: #333;
}

.authModalContent h2 {
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.8rem;
    text-align: center;
}

.authModalContent form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.authModalContent input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.authModalContent input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.authModalContent button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.8rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.authModalContent button[type="submit"]:hover {
    background-color: #0056b3;
}

.authModalContent p {
    margin-top: 1rem;
    text-align: center;
    color: #666;
}

.authModalContent p button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: underline;
}

.authModalContent p button:hover {
    color: #0056b3;
}

.authModalContent.visible {
    transform: translateY(0);
    opacity: 1;
}

.errorMessage {
    background-color: #fde2e2;
    border: 1px solid #f87171;
    color: #dc2626;
    padding: 0.75rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    animation: shake 0.5s ease-in-out;
}

.inputGroup {
    margin-bottom: 1rem;
    position: relative;
}

.authInput {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #fcd4e4;
    border-radius: 0.5rem;
    background-color: white;
    transition: all 0.2s;
}

.authInput:focus {
    outline: none;
    border-color: #ec4899;
    box-shadow: 0 0 0 3px rgba(236, 72, 153, 0.1);
}

.submitButton {
    width: 100%;
    padding: 0.75rem;
    background-color: #ec4899;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submitButton:hover {
    background-color: #db2777;
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    75% {
        transform: translateX(5px);
    }
}