.container {
    display: flex;
    height: 3.5rem;
    align-items: center;
    background-color: var(--bg-nav);
    padding: 0;
    color: var(--text-primary);
    width: 100%;
    border-bottom: 1px solid var(--bg-tertiary);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
}

.headerActions {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}




.inviteActions {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.inviteActions button {
    color: var(--text-white);
    border: none;
    padding: 8px 8px;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 10px;
    width: 50%;
}

.acceptButton {
    background-color: var(--bg-primary);
    color: var(--text-white);
    transition: background-color 0.2s ease;
}

.acceptButton:hover {
    background-color: var(--button-primary-hover);
}

.declineButton {
    background-color: var(--button-primary);
    color: var(--text-white);
    transition: background-color 0.2s ease;
}

.declineButton:hover {
    background-color: var(--button-secondary-hover);
}

.userMenu {
    position: relative;
}

.avatarButton {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0.5rem;
    font-size: 1rem;
}

.dropdownContent {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: var(--bg-tertiary);
    color: black;
    border: 1px solid;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(255, 105, 180, 0.1);
    z-index: 1000;
    min-width: 200px;
    padding: 1rem;

    /* Animation properties */
    transform-origin: top right;
    animation: slideDown 0.2s ease forwards;
}

.dropdownContent button {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    color: var(--text-primary);
    margin-bottom: 0.5rem;

    /* Animation properties */
    animation: fadeIn 0.3s ease forwards;
    animation-delay: calc(0.1s * var(--index));
    opacity: 0;
}

.dropdownContent button:hover {
    background-color: var(--bg-secondary);
}

/* If you have a header in the dropdown */
.dropdownHeader {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-primary);
}

.notificationMenu {
    position: relative;
}

.notificationDropdown {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    color: black;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(255, 105, 180, 0.1);
    z-index: 1000;
    min-width: 300px;
    padding: 1rem;

    /* Animation properties */
    transform-origin: top right;
    animation: slideDown 0.2s ease forwards;
}


.themeToggle {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: var(--text-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}


@keyframes slideDown {
    from {
        opacity: 0;
        transform: scale(0.95) translateY(-10px);
    }

    to {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

.notificationTitle {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-primary);
}

.notificationList {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.notificationItem {
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);

    /* Animation properties */
    animation: fadeIn 0.3s ease forwards;
    animation-delay: calc(0.1s * var(--index));
    opacity: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.notificationItem:hover {
    background-color: var(--bg-secondary);
}

.notificationItem h4 {
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: var(--text-primary);
}

.notificationItem p {
    font-size: 0.875rem;
    color: var(--text-tertiary);
    margin-bottom: 0.25rem;
}

.notificationItem span {
    font-size: 0.75rem;
    color: var(--text-primary);
}

.notificationDropdown,
.dropdownContent {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;

    /* Adjust positioning for smaller screens */
    @media (max-width: 768px) {
        right: -1rem;
        min-width: 250px;
    }
}

.dropdownItem {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 12px 16px;
    border: none;
    background: none;
    cursor: pointer;
    transition: all 0.2s ease;
    color: var(--text-tertiary);
    position: relative;
    overflow: hidden;
}

.dropdownItem:hover {
    background-color: var(--bg-secondary);
        color: var(--text-primary);
    --icon-filter: brightness(0) saturate(100%) invert(73%) sepia(32%) saturate(7076%) hue-rotate(308deg) brightness(101%) contrast(98%);
}

/* Settings icon rotation animation */
.dropdownItem:hover img[alt="settings"] {
    animation: rotateGear 2s linear infinite;
}

@keyframes rotateGear {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.logo {
    width: 100px;
    cursor: pointer;
}

.logo:hover {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}

/* Logout door animation */
/* .dropdownItem:hover img[alt="logout"] {} */



/* Profile icon bounce animation */
.dropdownItem:hover img[alt="profile"] {
    animation: bounce 0.5s ease;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-4px);
    }
}

/* Optional: Add a subtle scale effect to all icons */
.dropdownItem img {
    transform: scale(1);
    transition: transform 0.2s ease;
}

.dropdownItem:hover img {
    transform: scale(1.1);
}

/* Optional: Add text fade out if you want to show only icons */
.dropdownItem span {
    opacity: 1;
    transition: opacity 0.2s ease;
}

.dropdownItem:hover span {
    opacity: 0;
}

/* Common styles for all icon buttons */
.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    color: var(--text-primary);
}

/* Icon wrapper for consistent styling */
.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 0.75rem;
    background-color: var(--bg-tertiary);
    transition: all 0.2s ease;
}

.iconButton:hover .iconWrapper {
    background-color: var(--bg-secondary);
    transform: scale(1.05);
}

/* Theme toggle specific styles */
.themeToggle .iconWrapper {
    background-color: var(--bg-tertiary);
}

/* Notification button specific styles */
.notificationButton .iconWrapper {
    background-color: var(--bg-tertiary);
}

.notificationBadge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--accent-primary);
    color: white;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--bg-primary);
}

/* User button specific styles */
.userButton {
    padding: 0.375rem 0.75rem;
    border-radius: 0.75rem;
    background-color: var(--bg-tertiary);
}

.userButtonContent {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.username {
    font-size: 0.875rem;
    font-weight: 500;
}

.chevron {
    transition: transform 0.2s ease;
}

.chevronUp {
    transform: rotate(180deg);
}

/* Animation for icon interactions */
@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-2px);
    }
}

.iconButton:hover svg {
    animation: bounce 0.4s ease;
}

/* Dropdown refinements */
.dropdownContent,
.notificationDropdown {
    margin-top: 0.5rem;
    background-color: var(--bg-secondary);
    border: 1px solid var(--bg-tertiary);
    border-radius: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 0.75rem;
}

/* Optional: Add a subtle pulse animation for new notifications */
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.notificationBadge {
    animation: pulse 2s infinite;
}