*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --primary-color: #000000;
    --secondary-color: #ffffff;
    --accent-color: #ff4136;
    --background-color: #f4f4f4;
    --text-color: #333333;
    --card-border-radius: 8px;
    --spacing-small: 8px;
    --spacing-medium: 16px;
    --spacing-large: 24px;
}

body {
    font-family: Arial, sans-serif;
    color: var(--text-color);
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    background-image:
        linear-gradient(to right, rgba(0, 0, 0, 0.05) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
    background-size: 20px 20px;
}

button {
    cursor: pointer;
    border: none;
    padding: var(--spacing-small) var(--spacing-medium);
    border-radius: var(--card-border-radius);
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

button:hover {
    opacity: 0.9;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Add this new class for card-like elements */
.card {
    background-color: var(--secondary-color);
    border-radius: var(--card-border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
}

/* Add this for section headers */
.section-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: var(--spacing-medium);
    color: var(--primary-color);
}