/* MOBILE FIRST */

:root {
    --table-color: #87ced0;
    --background-color: #d25099;
    --card-color: #f4a2d0;
    --card-selected-color: #FFC0CB;
    --card-highlight-color: #FFD1DC;
    --text-color: #000000;
    --accent-color: #ff4136;
    --info-bg-color: rgba(255, 255, 255, 0.1);
    --overlay-bg-color: rgba(255, 255, 255, 0.8);
    --card-width: 130px;
    --card-height: 200px;
    --font-size: 14px;
}

.gameLayout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.gameContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: var(--text-color);
}

.topSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.bottomSection {
    position: relative;
    padding: 10px;
}

.gameStatus {
    background-color: var(--info-bg-color);
    padding: 15px;
    border-radius: 8px;
    margin: 10px;
}

.gameStage {
    text-align: center;
    z-index: 3;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
}

.blackCard {

    width: calc(var(--card-width) * 1.5);
    height: calc(var(--card-height) * 1.5);
    background-color: #000000;
    color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin-top: 10px;
    z-index: 2;
    position: relative;
}


.playerHand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
    gap: 10px;
}

.card {
    position: relative;
    flex: 1 1;
    width: var(--card-width);
    height: var(--card-height);
    max-width: var(--card-width);
    max-height: var(--card-height);
    background-color: var(--card-color);
    border: 2px solid white;
    border-radius: 8px;
    text-align: center;
    font-size: var(--font-size);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.selectedCard {
    transform: translateY(-10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 20;
    background-color: var(--card-selected-color);
}

.disabledCard {
    opacity: 0.5;
    cursor: not-allowed;
}

.cardContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px;
}

.cardText {
    font-size: 14px;
    line-height: 1.2;
    max-height: 80%;
}

.cardReferance {
    font-size: 6px;
    opacity: 0.5;
    text-align: right;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.blackCard .cardReferance {
    color: white;
}

.cardOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    border-radius: 8px;
}

.playCardsButton {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    padding: 5px;
    background-color: var(--button-primary);
    color: var(--text-secondary);
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    z-index: 10;
}

.playCardsButton:hover:not(:disabled) {
    background-color: color-mix(in srgb, var(--accent-color) 80%, black);
}

.playCardsButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.playingCard {
    position: fixed;
    z-index: 1000;
    animation: playCardAnimation 1s forwards;
}

@keyframes playCardAnimation {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }

    100% {
        transform: translate(var(--end-x), var(--end-y));
        opacity: 1;
    }
}

.infoButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 10px;
    right: 10px;
    gap: 10px;
}

.infoButton {
    padding: 5px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
}

.infoButton:hover {
    opacity: 0.8;
}

.returnButton {
    margin-top: 10px;
}

.scoreboardOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.scoreboardModal {
    background-color: var(--card-color);
    border-radius: 15px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scoreboardModal h2 {
    text-align: center;
    color: var(--text-color);
    margin-bottom: 20px;
}

.scoreTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.scoreTable th,
.scoreTable td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid var(--text-color);
}

.scoreTable th {
    font-weight: bold;
    background-color: var(--accent-color);
    color: white;
}

.scoreTable tr:last-child td {
    border-bottom: none;
}

.roundWinner {
    color: #2ecc71;
    /* A vibrant green color */

}

.closeButton {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.closeButton:hover {
    background-color: color-mix(in srgb, var(--accent-color) 80%, black);
}

.selectionOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.selectionModal {
    background-color: var(--background-color);
    border-radius: 15px;
    padding: 20px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.selectionModal h2 {
    color: var(--text-color);
    margin-bottom: 20px;
    text-align: center;
}

.selectionModal .blackCard {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
}

.playedCardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    width: 100%;
    padding: 10px;
}

.playedCardSlot {
    width: var(--card-width);
    height: var(--card-height);
    perspective: 1000px;
    position: relative;
}

.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.revealedCard .cardInner {
    transform: rotateY(180deg);
}

.cardBack,
.cardFront {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid white;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
}

.cardBack {
    background-color: var(--card-color);
    transform: rotateY(0deg);
}

.cardFront {
    background-color: var(--card-color);
    transform: rotateY(180deg);
}

.cardContent {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardContent .andSymbol {
    font-size: 12px;
    opacity: 0.5;
}

.revealRandomCardButton {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--card-color);
    color: black;
    border: 2px solid white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.revealRandomCardButton:hover {
    background-color: color-mix(in srgb, var(--card-selected-color) 80%, var(--card-color));
}

.revealRandomCardButton:disabled {
    background-color: color-mix(in srgb, var(--card-selected-color) 80%, var(--card-color));
    cursor: not-allowed;
}

.czarCard:hover {

    opacity: 0.8;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.stackedCard {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.chatArea {
    /* Add styles for chat area if needed */
}

/* Add these styles to your existing CSS file */

.notificationContainer {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1100;
}

.notification {
    background-color: var(--card-color);
    color: var(--text-color);
    border: 2px solid var(--accent-color);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 300px;
    animation: slideIn 0.3s ease-out, fadeOut 0.3s ease-in 2.7s forwards;
}

.notification.withReason {
    animation: slideIn 0.3s ease-out, fadeOutLonger 0.3s ease-in 60s forwards;
}

.notificationTitle {
    font-weight: bold;
    margin-bottom: 5px;
}

.notificationMessage {
    font-size: 14px;
    margin-bottom: 5px;
}

.notificationReason {
    font-size: 12px;
    font-style: italic;
    color: var(--text-color);
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOutLonger {

    0%,
    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.onlineUsers {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.onlineUser {
    margin: 5px 0;
    padding: 5px;
    background-color: #e0e0e0;
    border-radius: 3px;
}

.winnerCard {
    box-shadow: 0 0 15px #ff69b4, 0 0 30px #ff69b4, 0 0 45px #ff69b4;
    animation: neonGlow 1.5s ease-in-out infinite alternate;
}

@keyframes neonGlow {
    from {
        box-shadow: 0 0 15px #ff69b4, 0 0 30px #ff69b4, 0 0 45px #ff69b4;
    }

    to {
        box-shadow: 0 0 20px #ff69b4, 0 0 35px #ff69b4, 0 0 50px #ff69b4;
    }
}

.winnerModal {
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    color: white;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.winnerModal h2 {
    font-size: 24px;
    margin-bottom: 15px;
}

.winningCards {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.winningCards .blackCard,
.winningCards .winningWhiteCard {
    width: 200px;
    height: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
}

.winningCards .blackCard {
    background-color: black;
    color: white;
}

.winningCards .winningWhiteCard {
    background-color: white;
    color: black;
    box-shadow: 0 0 15px #ff69b4, 0 0 30px #ff69b4, 0 0 45px #ff69b4;
    animation: winningCardGlow 1.5s ease-in-out infinite alternate;
}

@keyframes winningCardGlow {
    from {
        box-shadow: 0 0 15px #ff69b4, 0 0 30px #ff69b4, 0 0 45px #ff69b4;
    }

    to {
        box-shadow: 0 0 20px #ff69b4, 0 0 35px #ff69b4, 0 0 50px #ff69b4;
    }
}

.winningCards h4 {
    font-size: 18px;
    margin-bottom: 10px;
}

.winningCards p {
    font-size: 16px;
}

.winningPlayerName {
    margin-top: 10px;
    font-size: 14px;
    font-style: italic;
}

/* Add these new styles */
.revealPrompt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.2em;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    z-index: 2;
    pointer-events: none;
}

.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.revealedCard .cardInner {
    transform: rotateY(180deg);
}

.cardFront,
.cardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.cardBack {
    background-color: #000;
    color: #fff;
}

.cardFront {
    background-color: #fff;
    color: #000;
    transform: rotateY(180deg);
}

.selectableCard {
    cursor: pointer;
    transition: transform 0.2s;
}

.selectableCard:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
}

.czarSelectable {
    cursor: pointer;
    transition: transform 0.2s;
}

.czarSelectable:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
}

/* Add these new styles while keeping existing animations and utilities */

.gameLayout {
    min-height: 100vh;
    background-color: #fdf2f8;
    /* pink-50 */
}

.header {
    background-color: #ec4899;
    /* pink-500 */
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
}

.mainContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.gameGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .gameGrid {
        grid-template-columns: 2fr 1fr;
    }
}

.gameTable,
.playersSection,
.playerHand {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.tableHeader,
.playersHeader,
.handHeader {
    padding: 1rem;
    border-bottom: 1px solid #f3f4f6;
}

.blackCardContainer {
    padding: 1.5rem;
}

.blackCard {
    background-color: black;
    color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.playedCardsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    padding: 1rem;
}

.playersList {
    padding: 1rem;
}

.playerItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: background-color 0.2s;
}

.playerItem:hover {
    background-color: #fdf2f8;
}

.playerInfo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.playerAvatar {
    width: 2rem;
    height: 2rem;
    background-color: #ec4899;
    color: white;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.playerStats {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.playerScore {
    color: #6b7280;
    font-size: 0.875rem;
}

.cardCzarBadge {
    background-color: #ec4899;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
}

.handGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    padding: 1rem;
}

.handCard {
    height: 8rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
}

.handCard:hover:not(.disabledCard) {
    background-color: #fdf2f8;
    border-color: #ec4899;
}

.selectedCard {
    background-color: #fdf2f8;
    border-color: #ec4899;
    transform: translateY(-0.25rem);
    box-shadow: 0 4px 6px -1px rgba(236, 72, 153, 0.1);
}

.handFooter {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #f3f4f6;
}

.playButton {
    background-color: #ec4899;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: background-color 0.2s;
}

.playButton:hover:not(:disabled) {
    background-color: #db2777;
}

.playButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}