.adminPanel {
    background-color: #fdf2f8;
    padding: 2rem;
    min-height: 100vh;
}

.adminHeader {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.adminTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ec4899;
    margin-bottom: 1rem;
}

.collectionSelect {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ffd1e6;
    border-radius: 0.375rem;
    background-color: white;
    color: #333;
    margin-bottom: 1rem;
}

.collectionSelect:focus {
    outline: none;
    border-color: #ec4899;
    box-shadow: 0 0 0 2px rgba(236, 72, 153, 0.2);
}

.documentsTable {
    width: 100%;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.tableHeader {
    background-color: #fdf2f8;
    color: #ec4899;
    font-weight: 600;
}

.tableHeader th {
    padding: 1rem;
    text-align: left;
    border-bottom: 2px solid #ffd1e6;
}

.tableRow {
    border-bottom: 1px solid #ffd1e6;
}

.tableRow:last-child {
    border-bottom: none;
}

.tableCell {
    padding: 1rem;
    vertical-align: top;
}

.idCell {
    font-family: monospace;
    color: #666;
}

.dataCell {
    max-width: 500px;
}

.dataTextarea {
    width: 100%;
    min-height: 100px;
    padding: 0.5rem;
    border: 1px solid #ffd1e6;
    border-radius: 0.375rem;
    font-family: monospace;
    resize: vertical;
}

.dataTextarea:focus {
    outline: none;
    border-color: #ec4899;
    box-shadow: 0 0 0 2px rgba(236, 72, 153, 0.2);
}

.dataPre {
    white-space: pre-wrap;
    word-break: break-all;
    font-family: monospace;
    max-height: 200px;
    overflow-y: auto;
}

.actionButtons {
    display: flex;
    gap: 0.5rem;
}

.editButton,
.deleteButton,
.saveButton,
.cancelButton {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    border: none;
}

.editButton {
    background-color: #ec4899;
    color: white;
}

.editButton:hover {
    background-color: #db2777;
}

.deleteButton {
    background-color: #ef4444;
    color: white;
}

.deleteButton:hover {
    background-color: #dc2626;
}

.saveButton {
    background-color: #10b981;
    color: white;
}

.saveButton:hover {
    background-color: #059669;
}

.cancelButton {
    background-color: #6b7280;
    color: white;
}

.cancelButton:hover {
    background-color: #4b5563;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.pageInfo {
    color: #666;
    font-weight: 500;
}

.paginationButton {
    background-color: #ec4899;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
}

.paginationButton:hover:not(:disabled) {
    background-color: #db2777;
}

.paginationButton:disabled {
    background-color: #f9a8d4;
    cursor: not-allowed;
}

.editFields {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
}

.fieldRow {
    display: flex;
    align-items: center;
    gap: 10px;
}

.fieldLabel {
    min-width: 120px;
    font-weight: bold;
}

.editInput {
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
}

.editInput:disabled {
    background-color: #f3f4f6;
    cursor: not-allowed;
    opacity: 0.7;
}

.imagePreview {
    max-width: 100px;
    max-height: 100px;
    margin-top: 5px;
}

.dataCell {
    min-width: 300px;
    max-width: 600px;
}

.editFields {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    background-color: white;
    /* Add this to ensure visibility */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fieldRow {
    display: flex;
    align-items: flex-start;
    /* Changed from center to flex-start */
    gap: 10px;
    padding: 8px 0;
    border-bottom: 1px solid #f3f4f6;
}

.fieldRow:last-child {
    border-bottom: none;
}

.fieldLabel {
    min-width: 120px;
    font-weight: bold;
    color: #333;
}

.editFields {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.imageField {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

@media (max-width: 768px) {
    .adminPanel {
        padding: 1rem;
    }

    .documentsTable {
        display: block;
        overflow-x: auto;
    }

    .dataCell {
        max-width: 300px;
    }
}

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #fdf2f8;
}

.loginForm {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.loginForm h2 {
    color: #ec4899;
    margin-bottom: 1.5rem;
    text-align: center;
}

.inputGroup {
    margin-bottom: 1rem;
}

.inputGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: #374151;
}

.inputGroup input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
}

.loginButton {
    width: 100%;
    padding: 0.75rem;
    background-color: #ec4899;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.loginButton:hover {
    background-color: #db2777;
}

.error {
    color: #ef4444;
    margin-bottom: 1rem;
    text-align: center;
}