.header {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerTitle {
    font-size: 1.5rem;
    font-weight: bold;
}

.headerControls {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.iconButton {
    background: none;
    border: none;
    color: var(--text-primary);
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
}

.iconButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
}


.mainContent {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.tabList {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    background: var(--bg-primary);
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.tabButton {
    flex: 1;
    padding: 1rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1.1rem;
    font-weight: 600;
    background: transparent;
    color: var(--text-tertiary);
    cursor: pointer;
    transition: all 0.2s;
}

.tabButton.active {
    background: var(--accent-primary);
    color: var(--text-white);
}

.card {
    background-color: var(--bg-secondary);
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cardTitle {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.formGroup {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    align-items: center;
}

.input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
}

.select {
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
}

.button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.button.primary {
    background-color: var(--accent-primary);
    color: var(--text-white);
}

.button.destructive {
    background-color: var(--button-primary);
    color: var(--text-white);
}

.cardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
}

.gameCard {
    aspect-ratio: 2/3;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.1rem;
    cursor: pointer;
}

.gameCard.white {
    background: white;
    color: black;
    border: 2px solid var(--border-color);
}

.gameCard.black {
    background: black;
    color: white;
}

.footer {
    background-color: var(--bg-secondary);
    text-align: center;
    padding: 1rem;
    margin-top: 2rem;
}

.footerLink {
    color: var(--accent-primary);
    text-decoration: none;
}

.footerLink:hover {
    text-decoration: underline;
}

.jsonUpload {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px dashed var(--border-color);
    border-radius: 4px;
}

.fileLabel {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--bg-secondary);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.fileLabel:hover {
    background-color: var(--border-color);
}

.fileInput {
    display: none;
}

.error {
    color: var(--button-primary);
    margin-top: 0.5rem;
    font-size: 0.875rem;
}

.jsonExample {
    margin-top: 1rem;
    font-size: 0.875rem;
}

.jsonExample pre {
    background-color: var(--bg-secondary);
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    margin-top: 0.5rem;
}

.jsonExample summary {
    cursor: pointer;
    color: var(--text-tertiary);
}

.jsonExample summary:hover {
    color: var(--text-primary);
}

.container {
    background: var(--bg-secondary);
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    min-height: 100vh;
}

.decksGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
}

.deckCard {
    background: var(--bg-primary);
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    animation: slideInUp 0.3s ease-out;
}

.deckCard:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.deckImageContainer {
    position: relative;
    padding-top: 133.33%;
    background: var(--bg-secondary);
}

.deckImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.deckInfo {
    padding: 1rem;
    background: linear-gradient(to bottom, var(--bg-primary), var(--bg-secondary));
}

.deckName {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
    color: var(--text-primary);
}

.deckStats {
    display: flex;
    gap: 0.5rem;
}

.statBadge {
    background: var(--accent-primary);
    color: var(--text-white);
    padding: 0.25rem 0.5rem;
    border-radius: 999px;
    font-size: 0.8rem;
}

.createDeckSection {
    margin-bottom: 2rem;
}

.createDeckButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    background: var(--bg-primary);
    color: var(--text-white);
    border: none;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.2s;
}

.createDeckButton:hover {
    background: var(--button-primary-hover);
}

.selectedDeckView {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 1000;
    padding: 2rem;
    overflow-y: auto;
}

.selectedDeckHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.closeButton {
    padding: 0.5rem 1rem;
    background: var(--bg-secondary);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.imageUploadSection {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.imageInput {
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.imagePreview {
    max-width: 200px;
    max-height: 200px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.imagePreview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
}

.modalContent {
    background: var(--bg-secondary);
    padding: 2rem;
    border-radius: 1rem;
    width: 90%;
    max-width: 500px;
    animation: slideIn 0.3s ease-out;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.modalContent h2 {
    color: var(--accent-primary);
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
}

.textarea {
    width: 100%;
    min-height: 120px;
    padding: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    background: var(--bg-primary);
    color: var(--text-primary);
    margin-bottom: 1rem;
    resize: vertical;
    font-family: inherit;
    transition: border-color 0.2s;
}

.textarea:focus {
    outline: none;
    border-color: var(--accent-primary);
}

.select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    background: var(--bg-primary);
    color: var(--text-primary);
    margin-bottom: 1rem;
    cursor: pointer;
    transition: border-color 0.2s;
}

.select:focus {
    outline: none;
    border-color: var(--accent-primary);
}

.formGroup {
    margin-bottom: 1rem;
}

.formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
    font-weight: 500;
}

.input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    background: var(--bg-primary);
    color: var(--text-primary);
    transition: border-color 0.2s;
}

.input:focus {
    outline: none;
    border-color: var(--accent-primary);
}

.modalButtons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.button {
    flex: 1;
    padding: 0.75rem;
    border: none;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.button:first-child {
    background: var(--accent-primary);
    color: var(--text-white);
}

.button:first-child:hover:not(:disabled) {
    background: var(--button-primary-hover);
}

.button:first-child:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button:last-child {
    background: var(--bg-primary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
}

.button:last-child:hover {
    background: var(--bg-secondary);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.hiddenInput {
    display: none;
}

.imagePreview {
    position: relative;
    width: 4rem;
    height: 4rem;
}

.imagePreview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.25rem;
}

.removeImageButton {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background: var(--accent-primary);
    color: var(--text-white);
    border: none;
    border-radius: 50%;
    padding: 0.25rem;
    cursor: pointer;
}

.jsonUploadSection {
    margin: 20px 0;
    padding: 15px;
    color: var(--text-primary);
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.errorList {
    margin-top: 15px;
    padding: 10px;
    background-color: #fff3f3;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.errorItem {
    margin: 10px 0;
    padding: 10px;
    background-color: var(--bg-secondary);  
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.error {
    color: var(--button-primary);
    margin: 10px 0;
}

.headerActions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.uploadButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: var(--text-tertiary);
    color: var(--text-white);
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
}

.uploadButton:hover {
    background-color: var(--text-primary);
}

.icon {
    width: 1rem;
    height: 1rem;
}

.progressContainer {
    margin: 1rem 0;
    background-color: var(--bg-secondary);
    border-radius: 0.5rem;
    height: 1.5rem;
    position: relative;
    overflow: hidden;
}

.progressBar {
    height: 100%;
    background-color: var(--accent-primary);
    transition: width 0.3s ease;
}

.progressText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--text-tertiary);
    font-size: 0.875rem;
    font-weight: 500;
    mix-blend-mode: difference;
    color: white;
}

.controlsContainer {
    background: var(--bg-primary);
    padding: 1.5rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.searchContainer {
    position: relative;
    margin-bottom: 1rem;
}

.searchInput {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 2px solid var(--border-color);
    border-radius: 0.5rem;
    background: var(--bg-secondary);
    color: var(--text-primary);
    font-size: 1rem;
    transition: all 0.2s;
}

.searchInput:focus {
    outline: none;
    border-color: var(--accent-primary);
    box-shadow: 0 0 0 3px rgba(236, 72, 153, 0.1);
}

.suggestionsDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--bg-primary);
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 10;
    animation: fadeIn 0.2s ease-out;
}

.suggestionItem {
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: all 0.2s;
}

.suggestionItem:hover {
    background: var(--bg-secondary);
    color: var(--accent-primary);
}

.filterControls {
    display: flex;
    gap: 1rem;
}

.filterSelect {
    flex: 1;
    padding: 0.75rem;
    border: 2px solid var(--border-color);
    border-radius: 0.5rem;
    background: var(--bg-secondary);
    color: var(--text-primary);
    cursor: pointer;
    transition: all 0.2s;
}

.filterSelect:focus {
    outline: none;
    border-color: var(--accent-primary);
}

.newCard {
    animation: highlightNew 2s ease-out;
}

@keyframes highlightNew {
    0%, 100% {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    50% {
        box-shadow: 0 0 20px var(--accent-primary);
    }
}

@keyframes slideInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.addNewCardButton {
    background-color: var(--accent-primary);
    color: var(--text-white);
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.addNewCardButton:hover {
    background-color: var(--button-primary-hover);
}

.uploadButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: var(--text-tertiary);
    color: var(--text-white);
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s;
}

.uploadButton:hover {
    background-color: var(--text-primary);
}

.closeButton {
    padding: 0.5rem 1rem;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s;
}

.closeButton:hover {
    background-color: var(--bg-secondary);
}

.headerActions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.icon {
    width: 1rem;
    height: 1rem;
}
