.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconWrapper {
  position: absolute;
}

.playedIcon {
  composes: iconWrapper;
  top: 0.5rem;
  right: 0.5rem;
}

.czarIcon {
  composes: iconWrapper;
  bottom: 0.5rem;
  left: 0.5rem;
}

.gradient {
  position: absolute;
  inset-inline: 0;
  bottom: 0;
  height: 33.333%;
}

.lockIcon {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

.icon {
  height: 1rem;
  width: 1rem;
  color: white;
}

/* Moving overlay-related styles from ModernUI.module.css */
.overlayIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  padding: 0.375rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  background-color: var(--accent-primary);
}

.playedGradient {
  background: linear-gradient(to top, var(--accent-primary-50), transparent);
}

.czarGradient {
  background: linear-gradient(to top, var(--accent-secondary-50), transparent);
}

.playedLock {
  color: var(--accent-primary-700);
}

.czarLock {
  color: var(--accent-secondary-700);
} 