/* MOBILE FIRST */

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.createGameCard {
    background: var(--bg-secondary);
    border: 2px solid var(--border-color);
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 0 20px hsl(336 74% 69% / 0.1);
    transition: box-shadow 0.3s ease;
}

.createGameCard:hover {
    box-shadow: 0 0 20px hsl(336 74% 69% / 0.1);
}

.createGameContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.gameNameInput {
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background: var(--bg-secondary);
    color: var(--text-primary);
}

.createButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: var(--button-primary);
    color: var(--text-white);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    transition: background-color 0.2s;
}

.createButton:hover {
    background: var(--button-primary-hover);
}

.gamesGrid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr;
}

.gameSection {
    background: var(--bg-secondary);
    border: 2px solid var(--border-color);
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 0 20px hsl(336 74% 69% / 0.1);
    transition: box-shadow 0.3s ease;
}

.gameSection:hover {
    box-shadow: 0 0 20px hsl(336 74% 69% / 0.1);
}

.sectionHeader {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    color: var(--text-primary);
}

.sectionIcon {
    color: var(--text-primary);
}

.sectionHeader h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
}

.gamesScrollArea {
    height: 300px;
    overflow-y: auto;
    padding-right: 1rem;
}

.searchContainer {
    position: relative;
    width: 100%;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dropdownItem {
    color: var(--text-primary);
    padding: 8px 12px;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: var(--bg-tertiary);
}

/* Responsive styles */
@media (min-width: 768px) {
    .createGameContent {
        flex-direction: row;
        align-items: center;
    }

    .createButton {
        width: auto;
    }

    .gamesGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.footer {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.manageDecksButton {
    padding: 0.5rem 1rem;
    background: var(--button-primary);
    color: var(--text-white);
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.2s;
}

.manageDecksButton:hover {
    background: var(--button-primary-hover);
}

/* Add glow to input on focus */
.gameNameInput:focus {
    outline: none;
    border-color: var(--button-primary);
    box-shadow: 0 0 10px hsl(336 74% 69% / 0.2);
}