.chatBox {
    position: fixed;
    left: 1rem;
    bottom: 1rem;
    width: 24rem;
    height: 400px;
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    pointer-events: none;
    background-color: var(--bg-secondary);
    border-radius: 0.5rem;
    color: var(--text-primary);
    z-index: 1000;
    opacity: 0.2;
}

.chatBox.active {
    opacity: 1;
}

.messagesContainer {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.5rem;
    scrollbar-width: thin;
    scrollbar-color: var(--accent-primary) transparent;
}

.messagesContainer::-webkit-scrollbar {
    width: 6px;
}

.messagesContainer::-webkit-scrollbar-track {
    background: transparent;
}

.messagesContainer::-webkit-scrollbar-thumb {
    background-color: var(--accent-primary);
    border-radius: 3px;
}

.inputContainer {
    padding: 0.5rem;
    background-color: var(--bg-tertiary);
    border-top: 1px solid var(--border-color);
    border-radius: 0 0 0.5rem 0.5rem;
    pointer-events: auto;
}

.inputForm {
    display: flex;
    gap: 0.5rem;
}

.input {
    flex: 1;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid var(--border-color);
    background-color: var(--bg-tertiary);
    color: var(--text-primary);
}

.input:focus {
    outline: none;
    border-color: var(--accent-primary);
}

.sendButton {
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: none;
    border: none;
    color: var(--accent-primary);
    cursor: pointer;
    transition: color 0.2s;
}

.sendButton:hover {
    color: var(--accent-primary-700);
}

.sendIcon {
    width: 1.25rem;
    height: 1.25rem;
}

.messageWrapper {
    padding: 0.25rem 0;
    transition: background-color 0.2s;
    border-radius: 0.25rem;
}

.messageWrapper:hover {
    background-color: var(--bg-tertiary);
}

.username {
    font-weight: 600;
    margin-right: 0.5rem;
}

.userUsername {
    color: var(--accent-primary);
}

.otherUsername {
    color: var(--accent-secondary-700);
}

.message.system {
    color: var(--accent-primary-50);
    font-style: italic;
}

.message.user {
    color: var(--accent-primary);
    stroke: white
}

.message.other {
    color: var(--accent-secondary-700);
}

.messageTime {
    font-size: 0.75rem;
    color: var(--text-tertiary);
    margin-left: 0.5rem;
}

.chatBox.active .messagesContainer {
    pointer-events: auto;
}